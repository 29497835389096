<script>
import { DATE_FORMAT_SHORT_REVERS, GET_DAY, GET_TIME } from '@/constants/date'
import { GET_PREPARE_CULTURE_FROM_STATE } from '@/views/control/store/actions'
import { autoType } from '@/constants/auto-type'
import { getTime, phoneRegex } from '@/core'
import { mapGetters } from 'vuex'

export default {
  name: 'ManagerTableCell',
  props: {
    row: {
      type: Object,
      default: () => ({}),
    },
    value: {
      type: [String, Array, Number],
      default: undefined,
    },
    propKey: {
      type: String,
      default: '',
    },
    command: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters({ getPrepareCultures: GET_PREPARE_CULTURE_FROM_STATE }),
  },
  methods: {
    commandDefault(val) {
      return val
    },
    commandPhone(val) {
      return phoneRegex(val)
    },
    commandTime(val, createElement) {
      const { buffer_date, buffer_time } = this.row

      return [
        { label: buffer_date, classes: null },
        { label: '   ', classes: null },
        {
          label: buffer_time,
          classes: 'parkman-page__orange',
        },
      ].map(item => createElement('span', { class: item.classes }, item.label))
    },
    commandParking(val, createElement) {
      const { parking_date, parking_time } = this.row

      return [
        { label: parking_date, classes: null },
        { label: '   ', classes: null },
        {
          label: parking_time,
          classes: 'parkman-page__orange',
        },
      ].map(item => createElement('span', { class: item.classes }, item.label))
    },
  },
  render(createElement) {
    let classNames = [this.propKey]
    let renderData = [this.value || 'нет данных']

    if (this.command) {
      renderData = this[this.command](this.value, createElement)
    }

    if (Array.isArray(this.value)) {
      renderData = this.value.map(item => createElement('div', item))
    }

    return createElement('div', { class: classNames }, renderData)
  },
}
</script>
